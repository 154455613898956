/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import Head from "next/head";

import { PrimaryButton, TextField, PasswordField } from "@atoms/index";
import { useState } from "react";
import { login } from "@services/session.service";
import { getAuth, isAuthenticated, setAuth } from "@services/identity.service";
import { useRouter } from "next/router";

const Login = () => {
  const router = useRouter();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const loginUser = async () => {
    setLoading(true);
    const response = await login(email, password);
    if (response.status && response.entity.status === 'ACTIVE') {
      const user = response.entity;
      setAuth(user);
      router.push("/requirements");
    } else {
      setMessage(response.message);
    }
    setLoading(false);
  };

  const handleKeypress = e => {
    if (e.keyCode === 13) {
      loginUser();
    }
  };

  useEffect(() => {
    const auth = getAuth();
    if (isAuthenticated(auth)) {
      router.push("/requirements");
    } else {
      router.push("/");
    }

  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeypress, false);
    return () => {
      document.removeEventListener("keydown", handleKeypress, false);
    };
  })

  return (
    <div className="relative w-full h-full py-40 min-h-screen" >
      <Head>
        <title>Valor Login</title>
      </Head>

      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                    Sign in with
                  </h6>
                  {message != "" && (
                    <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-500">
                      <span className="text-xl inline-block mr-5 align-middle">
                        <i className="fas fa-bell"></i>
                      </span>
                      <span className="inline-block align-middle mr-8">
                        {message}
                      </span>

                      <button className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none">
                        <span>×</span>
                      </button>
                    </div>
                  )}
                </div>
                <div>
                  <form>
                    <div className="relative w-full mb-3">
                      <TextField
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                      ></TextField>
                    </div>

                    <div className="relative w-full mb-3">
                      <PasswordField
                        placeholder="Password"
                        label="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      ></PasswordField>
                    </div>

                    <div className="text-center mt-6">
                      <PrimaryButton onClick={loginUser} loading={loading} title={"SignIn"}>
                      </PrimaryButton>
                    </div>
                    <span className='text-dark-blue cursor-pointer'
                      onClick={() => router.push("/reset-password")}
                    >Forgot password?</span>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
